.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
footer {
  background-image: url(./slide/footer-bg.jpg);
  padding: 170px 0px;
  background-repeat: no-repeat;
  background-position-x: 97px;
  display: block;
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
p {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  line-height: 26px;
  color: black;
}
footer h2 {
  margin-bottom: 25px;
  margin-top: 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #ffffff;
  text-transform: uppercase;
}
footer .company-pages .first-list {
  margin-top: 5px;
  margin-right: 50px;
  float: left;
}
footer .company-pages ul {
  padding: 0;
  margin: 0;
    margin-top: 0px;
    margin-right: 0px;
  list-style: none;
  display: inline-block;
}
footer .company-pages .second-list {
  margin-top: 5px;
  margin-right: 0px;
}
footer .company-pages ul li {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px dashed #4a4a4a;
  width: 100%;
}
footer .company-pages ul li a {
  color: #164194;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}
.btn-primary{
  --bs-btn-hover-color: #fff;
}
footer .company-pages ul li i {
  color: #F14820;
  margin-right: 10px;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
    font-size: 14px;
  font-size: inherit;
  text-rendering: auto;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icons{
  float: left;
  width: 50%;
  color: red;

}
.icons a {
  padding-right: 8px;
}

.liicons {
  display: inline-block;
  margin-left: 15px;
  line-height: 60px;
}

.ulicons{
 
  padding: 0;
  margin: 0;
  list-style: none;
}

.p_sub_footer{
  line-height: 60px;
  font-size: 16px;
  color: #888888;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
 
}
.subFooter{
  background-color: white;
  height: auto;
  width: auto;
  margin-top: 6vw;
}
.liicons a {
  color: black;
}
.liicons a:hover {
  color: #F14820;
}
#row{
  margin-left: 50px;
  margin-right: 50px;
  padding-top: 15px;
}
.form_sub{
  margin-bottom: 15vw;
}
.dd{
  margin-top: 0px;
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.ulcontact{
  padding: 0;
  /*margin-top: 30px;*/
  list-style: none;
  /*margin-bottom: 10px;*/
}
.ulcontact li {
  display: block;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  margin: 12px 0px;
  color: #888888;
}
.ulcontact li i {
  color: #373636;
  margin-right: 10px;
  font-size: 16px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
}
.uconltact li i a{
  text-decoration: none;
  color: #373636;
  margin-right: 10px;
  font-size: 16px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
}
.uconltact li {
  text-decoration: none;
  color: #373636;
  margin-right: 10px;
  font-size: 16px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
}

.linkc{
  color: #0160D6;
  text-transform: uppercase;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
 
}
#linkc:hover{
  color: rgb(234, 61, 20);
}

.ulheader li {
  position: relative;
  display: inline-block;
  
  padding: 20px 64px;
 
  border-right: solid rgba(0,0,0,.1) 0.01px;
}

.justify-content-end{
  float: left;
}


.item {
  float: left;
  height: 11vw;
  width: 17vw;

  margin-right: 3vw;
  margin-left: 4vw;
}
.subhead{
  
  width: 100%;
 
  
}

.row{
  margin-left: 15px;
  margin-right: 15px;
}
.linedec{
  height: 2px;
  width: 50px;
  background-color: #F14820;
}

.projects-page{
  margin-top: -110px;
 
  background-attachment: fixed;
  background-color: #ffffff;
}
.mix{
  margin-top: 30px;
  width: 33.33333333333333%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.thumb-content{
  visibility: hidden;
}
.thumb-holder:hover + .thumb-content {
  cursor: default;
  visibility: visible;
}
.thumb-link{ 
  text-align: right;
  margin: 0px;
}
.thumb-text {
  margin-top: 30%;
  text-align: center;
}
.thumb-text span {
  font-family: '', sans-serif;
  color: #F14820;
  font-weight: 500;
  font-size: 50px;
}
.thumb-text h4 {
  font-family: 'Montserrat', sans-serif;
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 700;
}
.thumb-content {
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  margin: 0px 0px 0px 15px;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(302, 247, 250, 0.8);
}
#cta-1{
  padding: 20px 0px;
  background-color: #007bff;
}
@media (min-width: 992px) {
  .col-md-offset-1 {
    margin-left: 8.333333333333332%;
  }
}
@media (min-width: 992px) {
  .col-md-10 {
    width: 83.33333333333334%;
  }
}
.cta-content{
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.428571429;
  color: white;
}
.cta-content h6 {
  margin-top: 15px;
  float: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 0.1px;
}
.fullwidthbanner-container {
  width: 100%;
  position: relative;
  padding: 0;
  overflow: visible;
}

.fullwidthbanner-container .fullwidthbanner {
  width: 100%;
  position: relative;
  max-height: 680px;
  height: 680px;
}

.ItemImg {
  width: 100%;
}


#linkc{
  padding: 15px;
  border-radius: 8px;
  color: #0160D6;
}
.me-auto{
  color: #0160D6;
  text-transform: uppercase;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  font-size: 100%;
}
#link1 {
  color: rgb(3, 55, 210);
  padding: 20px;
}
#link2 {
  color:  rgb(3, 55, 210);
  padding: 20px;
}
#link3 {
  color:  rgb(3, 55, 210);
  padding: 20px;
}
#link4 {
  color:  rgb(3, 55, 210);
  padding: 20px;
}
#link5 {
  color:  rgb(3, 55, 210);
  padding: 20px;
}


#link1:hover{
  color: rgb(6, 175, 191);
}
#link2:hover{
  color:rgb(6, 175, 191);
}
#link3:hover{
  color: rgb(6, 175, 191);
}
#link4:hover{
  color:rgb(6, 175, 191);
}
#link5:hover{
  color:rgb(6, 175, 191);
}
.carousel-control-prev{
  visibility: hidden;
}
.carousel-control-next{
  visibility: hidden;
}
.carousel-indicators{
  visibility: hidden;
}
.idh3{
  font-size: 4rem;
  margin-top: -24vw;
  
  font-family: cursive;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
  transition: all 0s ease 0s;
}
.idh33{
  font-size: 3rem;
  font-weight: bold;
}
.idhh4{
  margin-top: 0px;
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0px;
  color: #007bff;
}
.idhp{
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  line-height: 26px;
  color: #888888;
  margin: 0 0 10px;
}
.ulidh{
  padding: 0;
  margin: 0;
  display: inline-block;
  list-style: none;
  margin-right: 45px;
}

.ulidh li {
  margin: 10px 0px;
  display: block;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: #888888;
}
.ulul li{
  color: black;
  font-size: 13px;
}
#cta-2{
  
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 0px;
  background-attachment: fixed;
}
@media (min-width: 992px) {
  .col-md-8 {
    width: 66.66666666666666%;
  }
}
@media (min-width: 992px) {
  .col-md-offset-1 {
    margin-left: 8.333333333333332%;
  }
}
#cta-2 h1 {
  margin-top: 0px;
  margin-bottom: 30px;
  font-family: 'Montserrat', sans-serif;
  font-size: 34px;
  font-weight: 700;
  color: black;
  text-transform: uppercase;
}
#cta-2 ul {
  padding: 0;
  margin: 0;
  list-style: none;
  color: black;
}
#cta-2 .buttons {
  margin-top: 30px;
}
#cta-2 .buttons .contact {
  float: left;
  margin-right: 10px;
}
.border-accent-button a {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  color: #ffffff;
  height: 40px;
  display: inline-block;
  line-height: 35px;
  padding: 0px 15px;
  border: 2px solid #ffffff;
  border-radius: 3px;
  letter-spacing: 0.5px;
  background: transparent;
  text-decoration: none;
}
.border-accent-button a:hover {
  background-color: #F14820;
  border-color: transparent;
  transition: all 0.5s;
}
#sub-header-2 {
  width: 100%;
  height: 50px;
  background-color: #007bff;
}
@media (min-width: 992px) {
  .col-md-6 {
    width: 50%;
  }
}
.col-md-6 {
  float: left;
}
#sub-header-2 .left-info ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#sub-header-2 .left-info ul li {
  padding-right: 15px;
  line-height: 50px;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  color: white;
}
#sub-header-2 .left-info ul li i {
  margin-left: 15px;
  margin-right: 10px;
  color: white;
  font-size: 13px;
}
#sub-header-2 .social-icons ul li i {
  width: 26px;
  height: 26px;
  display: inline-block;
  
  line-height: 26px;
  text-align: center;
  color: white;
  
  font-size: 12px;
}
#sub-header-2 .social-icons {
  float: right;
  margin-right: 100px;
  margin-top: -2px;
}
#sub-header-2 .social-icons ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#sub-header-2 .social-icons ul li {
  line-height: 50px;
  display: inline-block;
  margin-left: 5px;
}
.ulidh i {
  padding-right: 15px;
}
section {
  padding: 80px 0;
}
.section-heading h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 700;
  
  margin-bottom: 20px;
  color: white;
}
.section-heading .line-dec {
 
  height: 2px;
  background-color: #F14820;
  width: 100px;
  margin-bottom: 80px;
}
@media (min-width: 992px) {
  .col-md-4 {
    width: 33.33333333333333%;
  }
}
.services-3 .service-item i {
  color: #007bff;
  float: left;
  font-size: 28px;
  margin-right: 25px;
  width: 30px;
  height: 30px;
  background-color: transparent;

}
.services-3 .service-item h4 {
  margin-top: 0px;
  font-family: ''Montserrat'', sans-serif;
  font-size: 14px;
  color: #F14820;
  text-transform: uppercase;
  font-weight: 700;
}
p {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  line-height: 26px;
  color: #888888;
}
.col-md-4{
  margin-bottom: 30px;
}
.services-3 .service-item p {
  margin-left: 55px;
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.row {
  margin-left: 15px;
  margin-right: 15px;
}
.services-2 .service-item i {
  width: 70px;
  height: 70px;
  line-height: 68px;
  text-align: center;
  display: inline-block;
  border: 2px solid #007bff;
  border-radius: 50%;
  color: #F14820;
  font-size: 16px;
}
.services-2 .service-item {
  text-align: center;
}
.services-2 .service-item i:hover {
  color: #007bff;
  border-color: #F14820;
  cursor: pointer;
  transition: all 0.5s;
}
.services-2 .service-item h4 {
  margin-top: 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  color: #F14820;
}
.our-services .flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}
.our-services .front {
  z-index: 2;
  background-color: hsl(0, 0%, 96%);
  transform: rotateY(0deg);
  border-radius: 1vw;
}
.our-services .front, .our-services .back {
  -webkit-backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.our-services .front, .our-services .back {
  width: 270px;
  height: 250px;
}
.our-services .back {
  width: 270px;
  height: 250px;
}
.our-services .back {
  -webkit-backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.our-services .back {
  transform: rotateY(180deg);
  background-color: #F14820;
  border-radius: 1vw;
}
.our-services .front i {
  margin-top: 73px;
  width: 65px;
  height: 50px;
  text-align: center;
  display: inline-block;
  line-height: 50px;
  color: #007bff;
  font-size: 46px;
}
.our-services .front h2 {
  margin-top: 30px;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  color: #007bff;
  text-transform: uppercase;
  font-weight: 700;
}
.our-services .service-item:hover .flipper, .our-services .service-item.hover .flipper {
  cursor: pointer;
  transform: rotateY(180deg);
}
.our-services .flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}
.our-services .back h2 {
  color: #ffffff;
  margin-top: 45px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
}
.back a {
  text-decoration: none;
}
.projects-page {
  text-align: center;
}
a:active, a:hover {
  outline: 0;
}
a:hover, a:focus {
  color: black;
  text-decoration: none;
}
.projects-page .thumb-holder:hover .thumb-content {
  cursor: default;
  opacity: 1;
  visibility: visible;
  transition: all 1s;
}
.projects-page .thumb-holder:hover .thumb-content {
  cursor: default;
  visibility: visible;
}
.thumb-text a {
  text-decoration: none;
}
.homepage-4 .section-heading .line-dec {
  margin-right: auto;
  margin-left: auto;
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.recent-projects {
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}
section.full-width .section-heading {
  text-align: center;
}
.section-heading h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 20px;
}
section.full-width .section-heading .line-dec {
  margin-left: auto;
  margin-right: auto;
}
section.full-width .col-md-3.col-sm-6 {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 992px) {
  .col-md-3 {
    width: 25%;
  }
}
section.full-width .thumb-content {
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  margin: 0px;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(302, 247, 250, 0.8);
}
.recent-projects .thumb-link {
  text-align: right;
  margin: 0px;
}
.recent-projects .thumb-text {
  margin-top: 30%;
  text-align: center;
}
.recent-projects .thumb-text h4 {
  font-family: ''Montserrat'', sans-serif;
  font-size: 20px;
  color: #F14820;
  text-transform: uppercase;
  font-weight: 700;
}
.recent-projects .thumb-text span {
  font-family: 'Montserrat', sans-serif;
  color: black;
  font-weight: 400;
  font-size: 14px;
}
.recent-projects .thumb-holder:hover .thumb-content {
  cursor: default;
  opacity: 1;
  visibility: visible;
  transition: all 1s;
}
section.full-width .thumb-content {
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  margin: 0px;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(302, 247, 250, 0.8);
}
.mixc{
  width: 25%;
  padding-left: 0;
  padding-right: 0;
  float: left;
  position: relative;
  min-height: 1px;
}
section.full-width .thumb-holder img {
  width: 100%;
}
.our-services .service-item, .our-services .front, .our-services .back {
  width: 270px;
  height: 250px;
}
.our-services .service-item {
  margin-top: 45px;
  
}
.our-services .flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}
.our-services .front{
  width: 270px;
  height: 250px;
}
.our-services .front, .our-services .back {
  -webkit-backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.our-services .front {
  z-index: 2;
  background-color: #f6f6f6;
  transform: rotateY(0deg);
  border-radius: 1vw;
}
.our-services .back {
  width: 270px;
  height: 250px;
}
.our-services .back {
  -webkit-backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.our-services .back {
  transform: rotateY(180deg);
  background-color: #F14820;
  border-radius: 1vw;
}
.our-services .front i {
  margin-top: 73px;
  width: 65px;
  height: 50px;
  text-align: center;
  display: inline-block;
  line-height: 50px;
  color: #007bff;
  font-size: 46px;
}
.our-services .front h2 {
  margin-top: 30px;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  color: #007bff;
  text-transform: uppercase;
  font-weight: 700;
}
.our-services .back h2 {
  color: #ffffff;
  margin-top: 45px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
}
.our-services .back p {
  color: #ffffff;
  padding: 0px 20px;
}
.footer {
  /*background-image: url(../images/slide/63.jpg);*/
  padding: 170px 0px;
  background-repeat: no-repeat;
  background-position-x: 97px;
  display: block;
}
@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
}
footer h2 {
  margin-bottom: 25px;
  margin-top: 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #ffffff;
  text-transform: uppercase;
}
footer .company-pages .first-list {
  margin-top: 5px;
  margin-right: 50px;
  float: left;
}
footer .company-pages ul {
  padding: 0;
  margin: 0;
    margin-top: 0px;
    margin-right: 0px;
  list-style: none;
 
}
footer .company-pages ul li {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px dashed #4a4a4a;
  width: 100%;
}
footer .company-pages ul li a {
  color: #164194;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}
footer .company-pages .second-list {
  margin-top: 5px;
  margin-right: 0px;
}
.colf{
  width: 25%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.row {
  margin-left: -15px;
  margin-right: -15px;
}
.FooterIdh{
  background-image: url(./slide/4.jpg);
  width: 100%;
  height: 40vw;
  padding-top: 10vw;

}
.subFooter .container {
 
  background-color: #fff;
  border-top: 1px dashed black;
  color: black;
}
.ulidhn{
  list-style-type: none;
  margin: 0;
  padding-left: 0rem;
  padding-bottom: calc(5px/2);
  
}
.ulidhn li {
 
  font-weight: 500;
  line-height: 1.1;
  font-weight: 300;
  color: #888888;
  letter-spacing: .2em;
  text-transform: uppercase;
  font-family: 'Montserrat',sans-serif;
  margin-bottom: 30px;
}
.ulidhn li:hover {
  color: #f49e32;
}

.ulidhn a {
  list-style-type: none;
  text-decoration: none;
  color: #888888;
}
.ulidhn a:hover {
 
  color: #f49e32;
}
.FooterIdh p {
  font-size: 18px;
}
.ulidhn span {
  padding-left: 10px;
}
#idhfiliale{
  background-image: url(./slide/6.jpg);
}
#item1{
  background-image: url(./slide/888.png);
  background-repeat: no-repeat;
  background-size: contain;
}
#item2{
  background-image: url(./slide/8.png);
  background-repeat: no-repeat;
  background-size: contain;
}
#item3{
  background-image: url(./slide/images.png);
  background-repeat: no-repeat;
  background-size: contain;
}
#item4{
  background-image: url(./slide/88.png);
  background-repeat: no-repeat;
  background-size: contain;
}
#item5{
  background-image: url(./slide/8888.png);
  background-repeat: no-repeat;
  background-size: contain;
}
#item7{
  background-image: url(./slide/b2b.png);
  background-repeat: no-repeat;
  background-size: contain;
}
#item6{
  background-image: url(./slide/88888.png);
  background-repeat: no-repeat;
  background-size: contain;
}
#hhh {
  margin-top: 0px;
  font-family: Arial, 'Montserrat', Helvetica, sans-serif, cursive ;
  font-size: 48px;
  font-weight: 700;
  text-transform: uppercase;
  color: black;
  letter-spacing: 1px;
  margin-bottom: 9vw;
}
#hhhh{
  margin-top: 0px;
  font-family: cursive, 'Montserrat', sans-serif;
  font-size: 48px;
  font-weight: 700;
  text-transform: uppercase;
  color: black;
  letter-spacing: 1px;
  margin-bottom: 8vw;
}
#ddd{
  color: rgb(3, 55, 210);
  letter-spacing: .2em;
  text-transform: uppercase;
 
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: bold;
}
#dddd{
  color: rgb(3, 55, 210);
  letter-spacing: .2em;
  text-transform: uppercase;
  font-family: Arial, 'Montserrat',sans-serif;
  font-size: 30px;
  font-weight: bold;
}
#ddddd{
  color: rgb(3, 55, 210);
  letter-spacing: .2em;
  text-transform: uppercase;
  font-family:  Arial, 'Montserrat',sans-serif;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: bold;
}
#dddddd{
  color: rgb(3, 55, 210);
  letter-spacing: .2em;
  text-transform: uppercase;
  font-family:  Arial, 'Montserrat',sans-serif;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: bold;
}
#ddm{
  margin-bottom: 2vw;
}
#dddm{
  margin-bottom: 2vw;
}
#ddmm{
  margin-bottom: 2vw;
}
.vfg{
  background-image: url(./slide/3.png);
  background-size: cover;
}
.vfgg{
  background-image: url(./slide/mlm.png);
  

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  background-position-x: 3px;
  height: 52vw;
}
.services-2{
            background-image: url(slide/7.png);
						background-size: contain;
						background-repeat: no-repeat;
						background-position: center;
}
.colmd12{
                  margin-bottom: 84px;
									text-align: center;
									box-sizing: border-box;
									padding: 53px 17%;
									border: 2px dashed #f2f2f2;
}
#hj{
                    margin: 8px 0 0;
										font-family:Arial, 'Montserrat',sans-serif;
										font-size: 22px;
										line-height: 33px;
										color: #000;
                    
                   
}
.ns{
  width: 60%;
  
  margin-top: 55px;
}
.bvv{
  
    width: 69%;
    margin: auto;
  
}
.bv{
                    font-size: 33px;
										line-height: 1.21em;
										font-weight: 400;
										letter-spacing: .2em;
										text-transform: uppercase;
										font-family: 'Montserrat',sans-serif;
										color: #000;
                    margin-top: 100px;
}
.card{
  width: 20rem;
}
.bvv{

  font-family: Arial,'Montserrat',sans-serif;
  font-size: 22px;
  line-height: 33px;
  color: #000;
}
.colmd4

#contactidd {
  background-image: url(./slide/3.png);
  background-size: cover;
  background-repeat: no-repeat;
  
}
#contactid{
  margin-bottom: 20vw;
}
.ulcontact li {
  float: left;
  margin-top: 17px;
  margin-left: 15px;
}
.services-22{
                        background-size: cover;
                        background-position: center;
                        background-position-x: center;
                        background-position-x: -160px;
}
.rowv{
                display: flex;
							
								background-position: center;
								background-position-x: center;
								background-position-x: -1px;
}
.h1idhh{
  color: #F14820;
  letter-spacing: .1em;
  text-transform: uppercase;
  font-family: 'Montserrat',sans-serif;
  margin-bottom: 30px;
  font-size: 33px;
}
.h1idhhh{
  color: rgb(6, 175, 191);
  letter-spacing: .1em;
  text-transform: uppercase;
  font-family: 'Montserrat',sans-serif;
  margin-bottom: 30px;
  font-size: 33px;
}

.h1idhhh:hover{
  color: #F14820;
  

}
.services22{
  background-image: url(./slide/98.jpg);
  background-size: cover;
  background-position: center;
  background-position-x: center;
  background-position-x: -160px;
}
.h1dd{
  color: #F14820;
                                        letter-spacing: .2em;
                                        text-transform: uppercase;
                                        font-family: 'Montserrat',sans-serif;
                                        margin-bottom: 30px;
                                        font-size: 34px;
}

.pwhy{
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  line-height: 26px;
  color: black;
 
}
.mydiv{
  background-image: url(./slide/9999.jpg);

  background-repeat: no-repeat;
}
.firstslide{
  background-image: url(./slide/1.jpg);
}
#idnn{
  
    margin-top: -16vw;
    margin-bottom: 12vw;
  
}
#sght{
  background-image: url(./slide/43.png);
  background-repeat: no-repeat;
  background-position: right;
  background-position-y: 190px;
}
#ol{
  margin-top: 163px;
  margin-left: -100px;
}
#oll{
  
  padding: 6vw;
  background-color: white;
  margin-top: 10px;
  margin-left: -112px;
  
}
.qsd{
  background-image: url(./slide/bv.jpg);
  background-position-y: -25px;
  background-position: center;
  
 
}
.hh1idhh {
  color: rgb(234, 61, 20);
  letter-spacing: .2em;
  text-transform: uppercase;
  font-family: 'Montserrat',sans-serif;
  font-size: 33px;
}
.hh1idhh:hover{
  color:rgb(6, 175, 191)
}
.qsd .container .row .col p {
  
    color: #0f2019;
    font-size: 18px;
    font-family: 'Montserrat',sans-serif;
  
}

Button a {
  color: white;
  text-decoration: none;
}

#ol img:hover {
  -webkit-filter: opacity(.5);
  filter: opacity(.8);
}
.FooterIdh .sd:hover {
  color: #F14820;
}
.hzoom:hover{
  transform: scale(1.1);
  transition: transform 1.05s;
}

.zoompart:hover{
  transform: scale(1.1);
  transition: transform 2.05s;
}
#uni:hover{
  color: #F14820;
}


.hdn:hover{
  transform: scale(1.1);
  transition: transform 2.05s;
}

.hzoomm:hover{
  transform: scale(1.02);
  margin-bottom: 40px;
}
.h1dd:hover{
  color: rgb(6, 175, 191);
  font-size: 40px;
}
.h1idhh:hover{
  color: rgb(6, 175, 191);
  /*font-size: 40px;*/
}
#dddd:hover{
  color: rgb(6, 175, 191);
}#ddddd:hover{
  color: rgb(6, 175, 191);
}#dddddd:hover{
  color: rgb(6, 175, 191);
}
#hhh:hover{
 color: rgb(234, 61, 20);
}
#item6:hover{
  transform: scale(1.02);
}
#item7:hover{
  transform: scale(1.02);
}
#item5:hover{
  transform: scale(1.02);
}
#item4:hover{
  transform: scale(1.02);
}
#item3:hover{
  transform: scale(1.02);
}
#item2:hover{
  transform: scale(1.02);
}
#item1:hover{
  transform: scale(1.02);
}




@media (max-width: 900px)  {
  .row {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 800px)  {
  #hhh {
    font-size: 28px;
  }
}


@media (max-width: 1565px)  {
  .hdn {
    display: none;
    visibility: hidden;
  }
}


@media (max-width: 1565px)  {
  .col-sm-4 {
   width: 0%;
  }
}

@media (min-width:1565px) {
  .col-sm-4 {
    
    width: 33.33333333%;
  }
}

@media (max-width: 1565px)  {
  .dsz {
    margin: auto;
    margin-left: 0px;
    width: 100%;

  }
}
@media (max-width: 1565px)  {
  .v54 {
    margin: auto;
    padding: 0px;
    width: 100%;

  }
}
@media (min-width: 1565px)  {
  .dsz {
    width: 70%;
    margin-left: 25vw;
  }
  
}
@media (max-width: 576px) {
  .zsv {
    flex: 0 0 auto;
    width:100%;
  }
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .card {
    width: 100%;
  }
}
@media (max-width: 1225px) {
  /* For mobile phones: */
  .hzoom {
    height: auto;
  }
}
@media (min-width: 1225px) {
  /* For mobile phones: */
  .hzoom {
    height: 20vw;
  }
}

.linkc:hover{
  color: #F14820;
}
.coll{
 
  
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.rowpp{
  background-color: #fff;
  border-top: 1px dashed black;
  color: black;
}


.Col-heading{
  background-image: url(./slide/9999.jpg);
  background-size: cover;
}

@media (max-width: 1200px) {
  /* For mobile phones: */
  .Col-heading {
    background-image:none;
  }
}






@media (max-width: 800px) {
  /* For mobile phones: */
  .resf {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
@media (max-width: 1227px) {
  /* For mobile phones: */
  .nbt {
    
    margin: auto;
    
  }
}
@media (max-width: 710px) {
 
  .col-sm-6 {
    
    width: 100%;
    
  }
}
@media (min-width: 576px) {
  .col-sm-6 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (max-width: 1020px) {
  .idh3 {
    display: none;
  }
}


@media (max-width: 480px) {
  .p_sub_footer {
    display: none;
  }
}
@media (max-width: 790px) {
  .about1 {
   width: 50vw;
  }
}

@media (max-width: 790px) {
  .hh1idhh {
   font-size: 18px;
  }
}

@media (max-width: 1000px) {
  .qsd {
   background-image: none;
  }
}

@media (max-width: 550px) {
  .ddm {
   width: 18rem;
  }
}

@media (max-width: 1200px) {
  #ol {
   display: none;
  }
}
@media (max-width: 1200px) {
  #oll {
   margin-left: 0px;
  }
}
@media (max-width: 1200px) {
  #idhidh {
   display: none;
  }
}
@media (max-width: 530px) {
  .imh {
   width: 80%;
  }
}


@media screen and (max-width: 1400px) {
  .msde {
       width: 100%;
 }
}
@media screen and (min-width: 1400px) {
  .msde {
       width: 33.33%;
 }
}
@media  (min-width: 997px),(max-width: 1220px) {
  #ddm {
    width: 23rem;
 }
}

@media (min-width: 997px),(max-width: 1220px) {
  #dddm {
    width: 23rem;
 }
}
@media  (min-width: 997px),(max-width: 1220px)  {
  #ddmm {
    width: 23rem;
 }
}
html {
  font-family: "myFont", "Bitstream Vera Serif", serif;
}

